const useDeviceColour = (deviceId) => {
    var result = 'black';

    switch (deviceId) {
        case '147471':
            result = 'purple';
            break;

        case '458278':
            result = 'green';
            break;

        default:
            break;
    }

    return result;
};

export default useDeviceColour