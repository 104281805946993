import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import MapTilerMap from "../components/MapTilerMap";
import MapTilerLine from "../components/MapTilerLine";

const MapPage = () => {
    const [data, setData] = useState();

    const LoadData = useCallback(() => {
        var url = process.env.NODE_ENV === "development" ? "https://localhost:5051/Request/devices-current" : "https://firstatom.duckdns.org/api/Request/devices-current";

        axios(url, {
            method: "GET",
            cors: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type"
            },
            // mode: "",
            // mode: "no-cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(
                (response) => {
                    var data = response.data.value;

                    data.forEach(element => {
                        element.coordinates = element.points.map((v,i,a) =>{ return [v.lon, v.lat]; });
                    });

                    setData(data);

                    // try {
                    //     var points = response.data.value;

                    //     //const newBounds = L.latLng(points[0].lat, points[0].lon).toBounds(10.0);
                    //     if (points.length && points.length > 0) {
                    //         points.map(marker => {
                    //             //newBounds.extend([marker.lat, marker.lon]);
                    //             return {};
                    //         });
                    //         //setBounds(newBounds);
                    //         console.log("😍");
                    //     }
                    // } catch (error) {

                    // }
                })
            .catch(
                (d) => {
                    setData([]);
                }
            )
            .finally(
                () => { }
            );
    }, []);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    // useEffect(() => {
    //     const interval = setInterval(
    //         () => LoadData(),
    //         10000,
    //     );

    //     return () => clearInterval(interval);
    // }, [LoadData]);


    return (
        <>
            <MapTilerMap>
                {/* <MapTilerMarker location={{ lon: -1.1169664753159594, lat: 51.24056970266853 }}></MapTilerMarker> */}
                {
                    data && data.map((item, index) => <MapTilerLine key={index} coordinates={item.coordinates} deviceId={item.deviceId} ></MapTilerLine> )
                }
            </MapTilerMap>
        </>
    );
};

export default MapPage;
