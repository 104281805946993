import React, { useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import Row from 'react-bootstrap/esm/Row';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';

async function loginUser(credentials) {

    var url = process.env.NODE_ENV === "development" ? "https://localhost:5051/auth" : "https://firstatom.duckdns.org/api/auth";

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

function Login(props) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = (e) => {
        e.preventDefault();
        loginUser({
            "user": username,
            "pass": password
        }).then(token => {
            props.setToken(`Bearer ${token.token}`);
        });
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <h1>Please Log In</h1>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup><Form.Label>Username</Form.Label>
                                <Form.Control onChange={e => setUserName(e.target.value)}></Form.Control>
                            </FormGroup>
                            <FormGroup><Form.Label>Password</Form.Label>
                                <Form.Control type='password' onChange={e => setPassword(e.target.value)}></Form.Control>
                            </FormGroup>
                            <FormGroup>
                                <Button type='submit'>Login</Button>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
};

export default Login;
