import { useContext, useEffect, useState } from "react";
import MapTilerContext from "./MapTilerContext";
import * as maptilersdk from '@maptiler/sdk';

const MapTilerMarker = ({ location }) => {
    const map = useContext(MapTilerContext);
    const [marker, setMarker] = useState(null);

    useEffect(() => {
        if (map) {
            // setTimeout(() => {
            //     new maptilersdk.Marker({ color: "#FF0000" })
            //     .setLngLat([marker.lon, marker.lat])
            //     .addTo(map);
            // }, 0);

            var newMarker = new maptilersdk.Marker({ color: "#FF0000" })
                .setLngLat([location.lon, location.lat])
                .setRotation(0)
                .setPopup(new maptilersdk.Popup().setHTML("<h1>Hello World</h1>"))
                .addTo(map);

            setMarker(newMarker);
            //console.log("create");
        }

        return () => {
            if (marker) {
                //console.log("remove");
                marker.remove();
            }
        };
    }, [map, location]);

    return (<></>);
};

export default MapTilerMarker;
