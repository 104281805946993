import { Link, Outlet } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Dashboard = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs="1" className="py-3">
            <nav>
              <ul>
                <li>
                  <Link to={``}>Dash</Link>
                </li>
                <li>
                  <Link to={`map`}>Map</Link>
                </li>
                <li>
                  <Link to={`live`}>Live</Link>
                </li>
                <li>
                  <Link to={`trip`}>Trip</Link>
                </li>
              </ul>
            </nav></Col>
          <Col xs="11" className="p-0">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
