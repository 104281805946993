import { useEffect, useRef, useState } from "react";
import * as maptilersdk from '@maptiler/sdk';
import "@maptiler/sdk/dist/maptiler-sdk.css";
import '../map.css';
import MapTilerContext from "./MapTilerContext";

const MapTilerMap = ({ children }) => {
    const mapContainer = useRef(null);
    //const map = useRef(null);
    const [map, setMap] = useState(null);
    const center = { lng: -1.1169664753159594, lat: 51.24056970266853 };
    const [zoom] = useState(14);
    //const [data, setData] = useState();

    maptilersdk.config.apiKey = 'LfpNo5H4k6bL2POOWGIS';

    useEffect(() => {
        if (map) return; // stops map from intializing more than once

        var newMap = new maptilersdk.Map({
            container: mapContainer.current,
            style: maptilersdk.MapStyle.STREETS,
            center: [center.lng, center.lat],
            zoom: zoom
        });

        newMap.addControl(new maptilersdk.FullscreenControl({
            container: document.querySelector('body')
        }));

        const scale = new maptilersdk.ScaleControl({
            maxWidth: 80,
            unit: 'metric'//'imperial'
        });
        newMap.addControl(scale);

        //scale.setUnit('metric');

        setMap(newMap);


    }, [center.lng, center.lat, zoom]);

    return (
        <>
            <div className="map-wrap">
                <div ref={mapContainer} className="map">
                    <MapTilerContext.Provider value={map}>
                        {children}
                    </MapTilerContext.Provider>
                </div>
            </div>
        </>
    );
};

export default MapTilerMap;
