import React, { useCallback, useEffect, useState } from "react";
import MapTilerMap from "../components/MapTilerMap";
import MapTilerLine from "../components/MapTilerLine";
import axios from "axios";
import MapTilerMarker from "../components/MapTilerMarker";
import { coordinates } from "@maptiler/sdk";

const TripPage = () => {
    const [data, setData] = useState();

    const LoadData = useCallback(() => {
        var url = process.env.NODE_ENV === "development"
            ? "https://localhost:5051/Request/trips"
            : "https://firstatom.duckdns.org/api/Request/trips";

        axios(url, {
            method: "GET",
            cors: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type"
            },
            // mode: "",
            // mode: "no-cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(
                (response) => {
                    var data = response.data.value;

                    data.forEach(element => {
                        //points[0].location.coordinates.x
                        element.coordinates = element.points.map((v, i, a) => { return [v.location.coordinates.x, v.location.coordinates.y]; });
                    });

                    setData(data);

                })
            .catch(
                (d) => {
                    setData([]);
                }
            )
            .finally(
                () => { }
            );
    }, []);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    return (
        <>
            <MapTilerMap>
                {
                    data && data.map(
                        (item, index) =>
                            <>
                                <MapTilerLine key={index} coordinates={item.coordinates} deviceId={item.deviceId} ></MapTilerLine>
                                <MapTilerMarker key={index} location={{lat: item.coordinates[0][1], lon: item.coordinates[0][0]}}></MapTilerMarker>
                            </>
                    )
                }
            </MapTilerMap>
        </>
    );
};

export default TripPage;
