import { useContext, useEffect, useId } from "react";
import MapTilerContext from "./MapTilerContext";
import useDeviceColour from "../hooks/useDeviceColour";
// import * as maptilersdk from '@maptiler/sdk';

const MapTilerLine = ({ coordinates, deviceId }) => {
    const sourceId = useId();
    const layerId = useId();
    const map = useContext(MapTilerContext);
    const colour = useDeviceColour(deviceId);

    useEffect(() => {
        if (map) {

            var sourceData = {
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    "properties": { },
                    "geometry": {
                        "type": "LineString",
                        "coordinates": coordinates
                    }
                }]
            };

            var source = map.getSource(sourceId);

            if (source == null)
            {
            map.addSource(
                sourceId,
                {
                    type: 'geojson',
                    data: sourceData
                });
            }
            else{
                source.setData(sourceData);
            }

            var layer = map.getLayer(layerId);

            if (layer == null)
            {
            // add the line which will be modified in the animation
            map.addLayer(
                {
                    'id': layerId,
                    'type': 'line',
                    'source': sourceId,
                    'layout': {
                        'line-cap': 'round',
                        'line-join': 'round'
                    },
                    'paint': {
                        'line-color': colour,
                        'line-width': 5,
                        'line-opacity': 0.8
                    }
                });
            }
        }

        return () => {
        };
    }, [map, coordinates, sourceId, layerId, colour]);

    return (<></>);
};

export default MapTilerLine;
