// import './App.css';
import { createBrowserRouter, redirect, RouterProvider } from "react-router-dom";
import Dashboard from './components/Dashboard';
import { useState } from 'react';
import Login from './components/Login';
import axios from 'axios';
import Live3Page from "./pages/Live3Page";
import MapPage from "./pages/MapPage";
import TripPage from "./pages/TripPage";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
      children: [
        {
          path: "map",
          element: <MapPage />,
        },
        {
          path: "live",
          element: <Live3Page />,
        },
        {
          path: "trip",
          element: <TripPage />,
        },
      ],
    }
  ]);

  const [token, setToken] = useState(null);

  function handleToken(token) {
    setToken(token);
    axios.defaults.headers.common['Authorization'] = token;
    redirect("/map");
  };

  return (
    <>
      {!token && <Login setToken={handleToken} />}
      {token && <>
        <RouterProvider router={router} >
        </RouterProvider>
      </>}
    </>
  );
}

export default App;
