import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import MapTilerMap from "../components/MapTilerMap";
import MapTilerMarker from "../components/MapTilerMarker";

const Live3Page = () => {
    const [data, setData] = useState();

    const LoadData = useCallback(() => {
        var url = process.env.NODE_ENV === "development" ? "https://localhost:5051/Request/live" : "https://firstatom.duckdns.org/api/Request/live";

        axios(url, {
            method: "GET",
            cors: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type"
            },
            // mode: "",
            // mode: "no-cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(
                (response) => {

                    setData(response.data.value);

                    try {
                        var points = response.data.value;

                        //const newBounds = L.latLng(points[0].lat, points[0].lon).toBounds(10.0);
                        if (points.length && points.length > 0) {
                            points.map(marker => {
                                //newBounds.extend([marker.lat, marker.lon]);
                                return {};
                            });
                            //setBounds(newBounds);
                            console.log("😍");
                        }
                    } catch (error) {

                    }
                })
            .catch(
                (d) => {
                    setData([]);
                }
            )
            .finally(
                () => { }
            );
    }, []);

    useEffect(() => {
        LoadData();
    }, [LoadData]);

    useEffect(() => {
        const interval = setInterval(
            () => LoadData(),
            10000,
        );

        return () => clearInterval(interval);
    }, [LoadData]);


    return (
        <>
            <MapTilerMap>
                {/* <MapTilerMarker location={{ lon: -1.1169664753159594, lat: 51.24056970266853 }}></MapTilerMarker> */}
                {data && data.map((item, index) =>
                <MapTilerMarker key={index} location={{lat: item.lat, lon: item.lon}}>
                    {/* <Popup>
                        <span>Speed: {item.speed}</span><br />
                        <span>Direction: {item.bearing}</span><br />
                        <span>Accuracy: {item.accuracy}</span>
                    </Popup> */}
                </MapTilerMarker>
            )}

            </MapTilerMap>
        </>
    );
};

export default Live3Page;
